$base-theme: Bootstrap;
$skin-name: Admin;
$swatch-name: Bootstrap;
$border-color: #d0d0d0;
$border-radius: 0.25rem;
$accent: #007bff;
$secondary: #eceff4;
$info: #17a2b8;
$success: #28a745;
$warning: #ffc107;
$error: #dc3545;
$body-bg: #ffffff;
$body-color: #111111;
$component-bg: #ffffff;
$component-color: #111111;
$card-cap-bg: #a0cf66;
$card-cap-color: #111111;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;
$menu_color: #ffffff;
$menu-bg: #373737;
$menu-width: 220px;
$criteria-bg: #eeeeee;
$breakpoint: 768px;
$fs-height: calc(100vh - 53px);
$fs-row-height: calc((100vh - 53px) / 3);
