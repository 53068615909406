/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import '~bootstrap/scss/bootstrap';

html, body {
  font-size: 11px;
  height: 100%;
}

.criteria {
  background-color: $criteria-bg;
  padding: 0.5em 0;
}

fieldset > legend {
  font-size: 1.25rem;
}

.k-button.k-state-active {
  color: #fff;
  background-color: $accent;
}

.k-grid {
  .k-grid-header th {
    font-weight: bold;
    text-align: center;
    white-space: normal;
  }

  .k-grouping-row td {
    font-weight: bold;
  }

  .currency {
    position: relative;
    text-align: right;

    &::before {
      content: "$";
      position: absolute;
      left: 0.25rem;
    }
  }

  .k-hierarchy-col {
    width: 16px;
  }

  .k-detail-cell {
    padding-left: 0;
    padding-right: 0;
  }
}

a:focus,
a:hover,
a:active,
a:visited,
.btn-link:focus,
.btn-link:hover,
.btn-link:active,
.btn-link:visited {
  text-decoration: none;
}

.k-upload .k-dropzone .k-dropzone-hint {
  color: #111;
}

@media (min-width: $breakpoint) {
  html, body {
    font-size: 14px;
  }

  .k-grid .k-hierarchy-col {
    width: 32px;
  }
}
